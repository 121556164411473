import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  UserLocationState,
  getFinanceFormInputPersonalised,
} from '~/common/store';
import { usePortals } from 'react-portal-hook';
import { GfvDisclaimer, PostcodeModal } from '~/common/components';
import { CompareRangePriceDetail } from '~/pages/model-landing/components/model-landing-price-info';
import { ModelEntry } from '../../types';
import styles from './index.module.scss';
import { Button } from '~/common/components/ui-elements';

interface VehicleModelProps {
  model: ModelEntry;
}
export const VehicleModel = React.memo<VehicleModelProps>(({ model }) => {
  const portalManager = usePortals();
  const locationState = useRecoilValue(UserLocationState);

  const openPostcodeModal = () => {
    portalManager.open((portal) => <PostcodeModal closeModal={portal.close} />);
  };

  return (
    <div className={styles.vehicleModel}>
      <img
        alt={model.modelName}
        src={model.variantImage}
        className={styles.variantImage}
      />
      <h2 className={styles.modelName}>{model.modelName}</h2>
      <div
        className={styles.modelBlurb}
        dangerouslySetInnerHTML={{ __html: model.modelBlurb }}
      />
      <p className={styles.variantName}>{model.variantName} shown</p>

      <CompareRangePriceDetail
        type="RDP"
        variantId={model.variantId}
        modelName={model.variantName}
        pimIdentifier={model.variantPimIdentifier}
        isFinanceFormInputPersonalised={getFinanceFormInputPersonalised()}
        containerClass={styles.priceDetailsContainer}
        labelClass={styles.priceLabelClass}
        priceClass={styles.priceClass}
        recommendedRdpLabelClass={styles.recommendedRdpLabelClass}
        recommendedRdpLabel="Recommended Driveaway Price"
      />
      <CompareRangePriceDetail
        type="Finance"
        variantId={model.variantId}
        pimIdentifier={model.variantPimIdentifier}
        isFinanceFormInputPersonalised={getFinanceFormInputPersonalised()}
        containerClass={styles.financeContainer}
        labelClass={styles.priceLabelClass}
        priceClass={styles.priceClass}
      />

      <div className={styles.pricingDisclaimer}>
        <p className={styles.rdpDisclaimer}>
          Recommended Driveaway Price (RDP) shown based on{' '}
          {`${locationState.name}, ${locationState.postcode}`}. RDP may change
          based on location.{' '}
          <span onClick={openPostcodeModal} className={styles.postcodeModal}>
            Enter your postcode
          </span>{' '}
          for the RDP in your area.
        </p>

        <GfvDisclaimer variantPimId={model.variantPimIdentifier} />
      </div>

      <div className={styles.ctaButtons}>
        {!model.explore?.ctaHidden && (
          <Button
            type="primary"
            buttonSize="large"
            buttonWidth="full-width"
            target={model.explore?.ctaUrl?.target || '_self'}
            href={model.explore?.ctaUrl?.url || `/${model.modelName}`}
            className={styles.fullSpecsFeatButton}
          >
            {model.explore?.ctaUrl?.name || `Explore ${model.modelName}`}
          </Button>
        )}
        {!model?.buildYourOwn?.ctaHidden && (
          <Button
            type="bordered"
            buttonSize="large"
            buttonWidth="full-width"
            href={model.buildYourOwn?.ctaUrl?.url || '/configure'}
          >
            {model.buildYourOwn?.ctaUrl?.name || `Build your own`}
          </Button>
        )}
      </div>
    </div>
  );
});
